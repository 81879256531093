import React from 'react'
import { Form } from 'react-final-form'
import { SubmitButtonProps } from 'components/common/submitButton'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import TextField from 'components/common/forms/textField'
import { validateAWSEmail } from 'appsync-scalar-utils'
import { FormControl, Grid, Skeleton, Typography } from '@mui/material'
import EmailField from 'components/common/forms/emailField'
import PasswordField from 'components/common/forms/passwordField'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: EditFormState) => void
    children: (
        content: React.ReactNode,
        actions: Array<SubmitButtonProps<React.ElementType>>,
    ) => React.ReactNode
    formClassName?: string
    inProgress: boolean
    initialValues: EditFormState
    loading: boolean
}

export interface EditFormState {
    username?: string
    password?: string
    passwordConfirmation?: string
    givenName?: string
    familyName?: string
    email?: string
}

interface EditFormError {
    username?: string
    password?: string
    passwordConfirmation?: string
    givenName?: string
    familyName?: string
    email?: string
}

const EditForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    children,
    formClassName,
    inProgress,
    initialValues,
    loading,
}) => {
    const { t } = useTranslation()

    const strongPasswordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\\W)(?=.{8,})')
    const validate = (values: EditFormState) => {
        const errors: EditFormError = {}

        if (!values.username) {
            errors.username = t('formValidation.username')
        }

        if (!values.givenName) {
            errors.givenName = t('formValidation.givenName')
        } else if (values.givenName.length < 2) {
            errors.givenName = t('formValidation.givenNameLength')
        }

        if (!values.familyName) {
            errors.familyName = t('formValidation.familyName')
        } else if (values.familyName.length < 2) {
            errors.familyName = t('formValidation.familyNameLength')
        }

        if (!values.email) {
            errors.email = t('formValidation.email')
        } else if (!validateAWSEmail(values.email)) {
            errors.email = t('formValidation.emailValid')
        }

        if (values.password && !strongPasswordRegex.test(values.password)) {
            errors.password = t('formValidation.passwordStrength')
        }

        if (
            (values.passwordConfirmation || values.password) &&
            values.password !== values.passwordConfirmation
        ) {
            errors.passwordConfirmation = t('formValidation.passwordConfirmationNotMatch')
        }
        return errors
    }

    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={onSubmit}
            validate={validate}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate className={formClassName}>
                    {children(
                        <div>
                            {loading ? (
                                <FormControl fullWidth margin="normal">
                                    <Skeleton width="100%" height={71} />
                                </FormControl>
                            ) : (
                                <TextField name="username" label={t('form.username')} required disabled />
                            )}
                            {loading ? (
                                <FormControl fullWidth margin="normal">
                                    <Skeleton width="100%" height={71} />
                                </FormControl>
                            ) : (
                                <EmailField name="email" label={t('form.email')} required />
                            )}
                            <Grid container spacing={2}>
                                <Grid item sm={6} xs={12}>
                                    {loading ? (
                                        <FormControl fullWidth margin="normal">
                                            <Skeleton width="100%" height={71} />
                                        </FormControl>
                                    ) : (
                                        <TextField
                                            name="givenName"
                                            label={t('form.givenName')}
                                            autoComplete="given-name"
                                            required
                                        />
                                    )}
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    {loading ? (
                                        <FormControl fullWidth margin="normal">
                                            <Skeleton width="100%" height={71} />
                                        </FormControl>
                                    ) : (
                                        <TextField
                                            name="familyName"
                                            label={t('form.familyName')}
                                            autoComplete="family-name"
                                            required
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <Typography sx={{ marginTop: 4 }}>
                                <Trans i18nKey="account.edit.password" />
                            </Typography>
                            <PasswordField
                                label={t('form.password')}
                                name="password"
                                autoComplete="new-password"
                                required
                            />
                            <PasswordField
                                name="passwordConfirmation"
                                autoComplete="new-password"
                                label={t('form.passwordConfirmation')}
                                required
                            />
                        </div>,
                        [
                            {
                                type: 'submit',
                                loading: submitting || inProgress,
                                children: <Trans i18nKey="actions.continue" />,
                            },
                        ],
                    )}
                </form>
            )}
        </Form>
    )
}
export default EditForm
