import React from 'react'
import { Container, Typography } from '@mui/material'
import { RouteComponentProps } from '@reach/router'
import Main from 'containers/common/main'
import { graphql, navigate } from 'gatsby'
import AccountEditView from 'components/views/account/accountEditView'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import { EditFormState } from 'components/forms/account/editForm'
import { useUpdateUser } from 'core/services/user/useUpdateUser'
import { useApiErrors } from 'core/services/api/useApiError'
import { useSnackbar } from 'notistack'
import withUser from 'containers/hoc/withUser'
import withAuthentication, { WithAuthenticationProps } from 'containers/hoc/withAuthentication'

interface Props extends RouteComponentProps, WithAuthenticationProps {}

const AccountEdit: React.FC<Props> = ({ user, isLoading }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const { displayError } = useApiErrors()
    const { updateUser, inProgress } = useUpdateUser()

    const handleEditAccount = (values: EditFormState) => {
        updateUser({
            email: values.email,
            familyName: values.familyName,
            givenName: values.givenName,
            password: values.password,
            username: values.username,
        })
            .then(() => {
                enqueueSnackbar(t('account.edit.success'))
                navigate('/mon-compte.html')
            })
            .catch(displayError)
    }

    return (
        <Main>
            <Container maxWidth="sm" sx={{ minHeight: 'calc(100vh - 64px)', paddingBottom: 4 }}>
                <Typography variant="h1" sx={{ marginBottom: 4 }}>
                    <Trans i18nKey="account.edit.title" />
                </Typography>
                <AccountEditView
                    loading={isLoading}
                    initialValues={{
                        ...user,
                    }}
                    inProgress={inProgress}
                    onSubmit={handleEditAccount}
                />
            </Container>
        </Main>
    )
}

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`

export default withAuthentication(withUser(AccountEdit))
