import { useState } from 'react'
import { UpdateUserRequest } from 'core/graphql/type'
import { mutations } from 'core/graphql/mutations'
import { generateError } from 'core/graphql/error'
import { useDispatch } from 'react-redux'
import actions from 'core/store/actions'
import { UserModel } from 'core/models/userModel'

export const useUpdateUser = () => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)

    const updateUser = (param: UpdateUserRequest): Promise<UserModel> => {
        setInProgress(true)
        return mutations
            .updateUser(param)
            .then((response) => {
                dispatch(actions.setUser(response))
                return response
            })
            .catch((error) => {
                throw generateError(error)
            })
            .finally(() => {
                setInProgress(false)
            })
    }

    return {
        updateUser,
        inProgress,
    }
}
