import React from 'react'
import { Card, CardActions, CardContent } from '@mui/material'
import EditForm, { EditFormState } from 'components/forms/account/editForm'
import SubmitButton from 'components/common/submitButton'
import { Trans } from 'gatsby-plugin-react-i18next'
import { Link } from 'gatsby'

interface Props {
    loading: boolean
    onSubmit: (values: EditFormState) => void
    inProgress: boolean
    initialValues: EditFormState
}

const AccountEditView: React.FC<Props> = ({ ...formData }) => {
    return (
        <Card sx={{ width: '100%' }}>
            <EditForm {...formData}>
                {(content, actions) => (
                    <>
                        <CardContent>{content}</CardContent>
                        <CardActions sx={{ justifyContent: 'flex-end' }}>
                            <SubmitButton variant="contained" component={Link} to="/mon-compte.html">
                                <Trans i18nKey="actions.previous" />
                            </SubmitButton>
                            {actions.map((action, index) => (
                                <SubmitButton
                                    sx={{ width: 160, marginLeft: 1 }}
                                    key={index}
                                    variant="contained"
                                    color="secondary"
                                    {...action}
                                />
                            ))}
                        </CardActions>
                    </>
                )}
            </EditForm>
        </Card>
    )
}

export default AccountEditView
